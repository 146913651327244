import styles from './styles.module.scss';

import React from 'react';
import cx from 'classnames';
import {NavLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Nav, NavLink as NavItemLink, NavItem} from 'reactstrap';

export default class ContentNav extends React.Component {
  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
          .isRequired,
        href: PropTypes.string,
        to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      })
    ).isRequired,
  };

  render() {
    const {items} = this.props;

    return (
      <Nav className={styles['content-nav']}>
        {items.map((item, index) => {
          return (
            <NavItem key={index} className={cx(item.className)}>
              {item.href ? (
                <NavItemLink
                  tag="a"
                  href={item.href}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.name}
                </NavItemLink>
              ) : (
                <NavItemLink
                  tag={NavLink}
                  exact={item.exact}
                  to={item.to}
                  target={item.target}
                >
                  {item.name}
                </NavItemLink>
              )}
            </NavItem>
          );
        })}
      </Nav>
    );
  }
}
