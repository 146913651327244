import styles from './styles.module.scss';

import React from 'react';
import classNames from 'classnames';
import {LoadingPage} from '../Loading';

import {Row, Col} from 'reactstrap';

const defaultProp = {
  tag: 'div',
  isLoading: false,
};

function ContentBox({
  title,
  isLoading,
  tag,
  className,
  innerClassName,
  id,
  children,
  actions,
  description,
  status,
}) {
  const _className = classNames(styles['content-box'], {
    [className]: !!className,
  });

  const _innerClassName = classNames(styles['content-box__inner'], {
    [innerClassName]: !!innerClassName,
  });

  return React.createElement(
    tag,
    {id, className: _className},
    <>
      <LoadingPage isLoading={isLoading} />
      {(title || actions) && (
        <div className={styles['content-box__title-wrapper']}>
          <Row className="align-items-center">
            <Col>
              <span
                className={classNames({
                  'd-flex align-items-center': status,
                })}
              >
                <h2 className={styles['content-box__title']}>{title}</h2>
                {status && status}
              </span>

              {description && <p className="text-secondary">{description}</p>}
            </Col>
            {actions && <Col className="col-auto">{actions}</Col>}
          </Row>
        </div>
      )}

      <div className={_innerClassName}>{children}</div>
    </>
  );
}

ContentBox.defaultProps = defaultProp;

export default ContentBox;
