import styles from './styles.module.scss';

import React from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import qs from 'qs';
import PropTypes from 'prop-types';
import {isEmpty} from 'lodash/lang';
import classNames from 'classnames';

import {Table, UncontrolledTooltip} from 'reactstrap';
import {IconFA} from 'components/Icons';
import {ContentPagination, ContentTableLimit} from 'components/Contents';
import {LoadingSpinner} from 'components/Loading';
import {isObject, kebabCase, omit, last} from 'lodash';
import cx from 'classnames';
import useFilter from 'hooks/useFilter';

TableColumn.defaultProps = {
  prepend: null,
  append: null,
};

function TableColumn({label, infoText, index}) {
  const hasInfo = !!infoText; // single tooltip || tooltips based on tab
  const id = kebabCase(label + index);

  const {text, prepend, append} = infoText || {};

  const icon = (
    <IconFA id={id} prepend={label} name="info-circle" className="text-muted" />
  );

  const _label = <span>{label}</span>;

  let content;
  if (prepend) {
    content = (
      <>
        {icon}
        {_label}
      </>
    );
  }

  if (append) {
    content = (
      <>
        {_label}
        {icon}
      </>
    );
  }

  return hasInfo ? (
    <th>
      <UncontrolledTooltip
        target={id}
        fade={false}
        placement="top"
        className="flex-nowrap"
        style={{maxWidth: '25rem', textAlign: 'left'}}
        modifiers={{preventOverflow: {boundariesElement: 'window'}}}
      >
        <small>{text}</small>
      </UncontrolledTooltip>

      {content}
    </th>
  ) : (
    <th>{label}</th>
  );
}

const ContentTableTooltip = ({id, children, placement = 'top'}) => {
  return (
    <span>
      <span id={`tooltip-${id}`} className="text-secondary cursor-pointer">
        <IconFA
          name="info-circle"
          className="text-secondary"
          id="TableTooltip"
        />
      </span>

      <UncontrolledTooltip
        placement={placement}
        target={`tooltip-${id}`}
        modifiers={{preventOverflow: {boundariesElement: 'window'}}} // prevents the placement issue when nested in a container with overflow auto
      >
        {children}
      </UncontrolledTooltip>
    </span>
  );
};

// Creating this function since we cannot directly decalare hooks inside the class component
function NumberLimiterComponent({value, total}) {
  const {updateFilter} = useFilter();

  return (
    <ContentTableLimit
      value={parseInt(value) || 0}
      total={parseInt(total) || 0}
      onChange={(limit) => updateFilter({offset: 0, limit: limit})}
    />
  );
}

// we used functional because withRouter returns staticContext props that triggers warning on console
function ContentTableSorting(props) {
  const history = useHistory();
  const location = useLocation();

  function handleChangeSort() {
    const {value} = props;
    const {search, pathname} = location;
    const {order, order_by} = qs.parse(search, {ignoreQueryPrefix: true});

    const query = omit(qs.parse(search, {ignoreQueryPrefix: true}), [
      'order',
      'order_by',
    ]);

    // reset pagination
    delete query.offset;

    if (value !== order_by) {
      // always start at DESC <- before
      // will replace to ASC since by default, the data starts at DESC order
      history.replace({
        pathname,
        search: qs.stringify({order: 'ASC', order_by: value, ...query}),
      });
      return;
    }

    if (value === order_by && order === 'ASC') {
      history.replace({
        pathname,
        search: qs.stringify({order: 'DESC', order_by: value, ...query}),
      });
      return;
    }

    if (value === order_by && order === 'DESC') {
      history.replace({
        pathname,
        search: qs.stringify({...query}),
      });
      return;
    }
  }

  const {label, value} = props;
  const {search} = location;
  const {order, order_by} = qs.parse(search, {ignoreQueryPrefix: true});
  const isOrdered = order_by === value;
  let icon;

  switch (order) {
    case 'ASC':
      icon = 'sort-up';
      break;
    case 'DESC':
      icon = 'sort-down';
      break;
    default:
      icon = 'sort';
  }

  return (
    <th onClick={() => handleChangeSort()} className="border-0">
      {/* Cheat since there is no duotone in react fontawesome we just used z index to make it icon stack to each other */}
      <div className="position-relative">
        <span>{label}</span>

        {isOrdered && (
          <IconFA name="sort" className={styles['content-table-sorting']} />
        )}
        <IconFA
          name={isOrdered ? icon : 'sort'}
          className={cx(styles['content-table-sorting'], {
            [styles['content-table-sorting--active']]: isOrdered,
          })}
        />
      </div>
    </th>
  );
}

const ContentTableSortingStateful = (props) => {
  const {label, value, tooltip, meta, onChangeSort} = props;
  const {order, order_by} = meta || {};
  const isOrdered = order_by === value;

  let icon;

  switch (order) {
    case 'ASC':
      icon = 'sort-up';
      break;
    case 'DESC':
      icon = 'sort-down';
      break;
    default:
      icon = 'sort';
  }

  const handleChangeSort = () => {
    if (!isOrdered) {
      onChangeSort('ASC');
      return;
    }

    if (isOrdered && order === 'ASC') {
      onChangeSort('DESC');
      return;
    }

    if (isOrdered && order === 'DESC') {
      onChangeSort();
      return;
    }
  };

  return (
    <th>
      <div className="d-flex align-items-center">
        {!!tooltip && (
          <ContentTableTooltip id={value}>{tooltip}</ContentTableTooltip>
        )}
        <span>{label}</span>

        <div
          className={cx('mr-3', styles['sort-stateful__container'])}
          onClick={() => handleChangeSort()}
        >
          <IconFA name="sort" className={styles['sort-stateful__icon']} />
          <IconFA
            name={isOrdered ? icon : 'sort'}
            className={
              isOrdered ? styles['sort-stateful__icon--active'] : 'd-none'
            }
          />
        </div>
      </div>
    </th>
  );
};

// usage for 'head' props head={['column 1', 'column 2', 'column 3]}
// usage for 'head' props head={[{label: 'label 1', value: 'value 1', ...}]}

export default class ContentTable extends React.Component {
  static propTypes = {
    head: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({label: PropTypes.string, value: PropTypes.string}),
      ])
    ),
    emptyMessage: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
  };

  static defaultProps = {
    meta: {},
    // Table theme options
    borderless: true,
    striped: false,
    hover: true,
    responsive: true,
    pagination: false,
    minHeight: 300,
    emptyMessage: (
      <span>
        <IconFA name="search" /> No Results Found
      </span>
    ),
  };

  render() {
    const {
      v2,
      nowrap,
      hover,
      head,
      className,
      pagination,
      isFetching,
      meta,
      minHeight,
      children,
      emptyMessage,
      dataLimiter,
      isStickyLast,
      loadMore,
      borderless,
      selectedItems,
      actionsHeader,
      ...rest
    } = this.props;

    return (
      <React.Fragment>
        {actionsHeader && (
          <div className="border-top border-bottom p-3 font-italic bg-light text-secondary">
            {isEmpty(selectedItems) && 'Select records to perform bulk actions'}
            {!isEmpty(selectedItems) && actionsHeader}
          </div>
        )}

        <Table
          borderless={borderless}
          hover={hover && !isEmpty(children)}
          className={classNames(
            v2
              ? nowrap
                ? styles['content-table-v2--nowrap']
                : styles['content-table-v2']
              : styles['content-table'],
            className
          )}
          {...rest}
        >
          {head && (
            <thead>
              <tr>
                {head
                  .filter((item) => !!item)
                  .map((item, index) => {
                    const isFilter =
                      isObject(item) &&
                      item.hasOwnProperty('label') &&
                      item.hasOwnProperty('value');

                    const isLast = last(head) === item;

                    if (item.hasOwnProperty('infoText')) {
                      return (
                        <TableColumn key={index} index={index} {...item} />
                      );
                    }
                    if (isFilter) {
                      const {onChangeSort, ...rest} = item;

                      return !!onChangeSort ? (
                        <ContentTableSortingStateful
                          key={index}
                          meta={meta}
                          onChangeSort={onChangeSort}
                          {...rest}
                        />
                      ) : (
                        <ContentTableSorting key={index} {...rest} />
                      );
                    } else if (isLast) {
                      return (
                        <th
                          key={index}
                          className={classNames('border-0', {
                            'bg-white': !v2,
                            'border-0': !borderless,
                          })}
                          style={{
                            position: isStickyLast ? 'sticky' : 'relative',
                            right: 0,
                            top: 0,
                            zIndex: 2,
                          }}
                        >
                          {item}
                        </th>
                      );
                    }

                    return (
                      <th key={index} className="border-0">
                        {item}
                      </th>
                    );
                  })}
              </tr>
            </thead>
          )}

          <tbody style={{minHeight: '300px'}}>
            {isFetching ? (
              <tr>
                <td
                  align="center"
                  colSpan={head.length}
                  height={minHeight}
                  style={{verticalAlign: 'middle'}}
                >
                  <LoadingSpinner />
                </td>
              </tr>
            ) : !isEmpty(children) ? (
              children
            ) : (
              <tr>
                <td
                  align="center"
                  colSpan={head.length}
                  height={minHeight}
                  style={{verticalAlign: 'middle'}}
                >
                  {emptyMessage}
                </td>
              </tr>
            )}
          </tbody>
        </Table>

        {!isFetching && (dataLimiter || pagination) && (
          <div className="d-flex justify-content-between align-items-center mx-3 my-3">
            <div>
              {dataLimiter && meta.total_rows >= 20 && (
                <NumberLimiterComponent
                  value={meta.limit}
                  total={meta.total_rows}
                />
              )}
            </div>

            {pagination && meta.total_pages > 1 && (
              <ContentPagination totalPages={meta.total_pages || 1} />
            )}
          </div>
        )}

        {loadMore}
      </React.Fragment>
    );
  }
}
